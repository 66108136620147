// src/App.js

import React, { useState, useEffect } from "react";
import './App.css';

function App() {
  const [clientId] = useState(() => Date.now().toString(36) + Math.random().toString(36).substring(2));

  const [file, setFile] = useState(null);
  const [numClips, setNumClips] = useState(2);
  const [clipDuration, setClipDuration] = useState(5);
  const [uploadedClips, setUploadedClips] = useState([]);
  const [uploadedDownloadAllLink, setUploadedDownloadAllLink] = useState(null);
  const [clipWholeVideo, setClipWholeVideo] = useState(false);
  const [randomClips, setRandomClips] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const [mixedVideo, setMixedVideo] = useState(null);
  const [mixClips, setMixClips] = useState([]);
  const [mixDuration, setMixDuration] = useState(30);
  const [mixError, setMixError] = useState(null);
  const [isMixing, setIsMixing] = useState(false);
  const [mixProgress, setMixProgress] = useState(0);

  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [youtubeNumClips, setYoutubeNumClips] = useState(3);
  const [youtubeClipDuration, setYoutubeClipDuration] = useState(10);
  const [youtubeClipWholeVideo, setYoutubeClipWholeVideo] = useState(false);
  const [youtubeRandomClips, setYoutubeRandomClips] = useState(false);
  const [youtubeClips, setYoutubeClips] = useState([]);
  const [youtubeDownloadAllLink, setYoutubeDownloadAllLink] = useState(null);
  const [youtubeError, setYoutubeError] = useState(null);

  const [progress, setProgress] = useState('');
  const [eventSource, setEventSource] = useState(null);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgressPercentage, setUploadProgressPercentage] = useState(0);

  const handleFileChange = (e) => setFile(e.target.files[0]);
  const handleClipUploadChange = (e) => setMixClips(Array.from(e.target.files));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploadError(null);
    setUploadedClips([]);
    setUploadedDownloadAllLink(null);
    setIsUploading(true);
    setUploadProgressPercentage(0);

    const formData = new FormData();
    formData.append("video", file);
    formData.append("numClips", numClips);
    formData.append("clipDuration", clipDuration);
    formData.append("clipWholeVideo", clipWholeVideo);
    formData.append("randomClips", randomClips);

    try {
      const response = await fetch("/upload", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        setUploadError(errorData.error || "Error processing the video");
        setIsUploading(false);
        return;
      }

      const result = await response.json();
      setUploadedClips(result.clips);
      setUploadedDownloadAllLink(result.downloadAll);
    } catch (err) {
      setUploadError("An error occurred. Please try again.");
      console.error("Error:", err);
    } finally {
      setIsUploading(false);
    }
  };

  const handleYoutubeSubmit = async (e) => {
    e.preventDefault();
    setYoutubeError(null);
    setProgress('');
    setYoutubeClips([]);
    setYoutubeDownloadAllLink(null);
    setProgressPercentage(0);
    setIsDownloading(true);

    const data = {
      url: youtubeUrl,
      numClips: youtubeNumClips,
      clipDuration: youtubeClipDuration,
      clipWholeVideo: youtubeClipWholeVideo,
      randomClips: youtubeRandomClips,
    };

    if (eventSource) eventSource.close();

    try {
      const es = new EventSource(`/youtube-progress?clientId=${encodeURIComponent(clientId)}`);
      setEventSource(es);

      es.onmessage = (e) => {
        const message = e.data;

        try {
          const parsedData = JSON.parse(message);
          if (parsedData.progress !== undefined) {
            setProgressPercentage(parsedData.progress);
          } else if (parsedData.clips) {
            setYoutubeClips(parsedData.clips);
            setProgress((prev) => prev + 'Splitting complete. Clips are available.\n');
          } else if (parsedData.downloadAll) {
            setYoutubeDownloadAllLink(parsedData.downloadAll);
          } else if (parsedData.error) {
            setYoutubeError(parsedData.error);
          }
        } catch (err) {
          if (message === 'Process ended.') {
            es.close();
            setEventSource(null);
            setIsDownloading(false);
          }
        }
      };

      es.onerror = (err) => {
        setYoutubeError("An error occurred while downloading the YouTube video. Please try again.");
        es.close();
        setEventSource(null);
        setIsDownloading(false);
      };

      const response = await fetch("/youtube", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Client-ID': clientId
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setYoutubeError(errorData.error || "Error starting YouTube download.");
        if (eventSource) {
          eventSource.close();
          setEventSource(null);
        }
        setIsDownloading(false);
      }
    } catch (err) {
      setYoutubeError("An error occurred while starting the YouTube download. Please try again.");
      if (eventSource) eventSource.close();
      setEventSource(null);
      setIsDownloading(false);
    }
  };

  const stopDownload = async () => {
    if (eventSource) {
      eventSource.close();
      setEventSource(null);
    }
    await fetch("/stop-download", {
      method: "POST",
      headers: {
        'Client-ID': clientId
      }
    });
    setIsDownloading(false);
    setProgress('Download stopped by user.');
    setProgressPercentage(0);
  };

  const handleMixSubmit = async (e) => {
    e.preventDefault();
    setMixError(null);
    setMixedVideo(null);
    setIsMixing(true);
    setMixProgress(0);

    const formData = new FormData();
    formData.append("mixDuration", mixDuration);
    mixClips.forEach((clip) => formData.append("clips", clip));

    try {
      const response = await fetch("/mix-clips", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        setMixError(errorData.error || "Error mixing clips");
        setIsMixing(false);
        return;
      }

      const result = await response.json();
      setMixedVideo(result.outputVideo);
    } catch (err) {
      setMixError("An error occurred while mixing clips. Please try again.");
    } finally {
      setIsMixing(false);
    }
  };

  return (
    <div className="app">
      <h1 className="title">ClipRipper</h1>

      {/* Upload and Split Section */}
      <section className="section">
        <h2>Upload Video and Split into Clips</h2>
        <form onSubmit={handleSubmit} className="form">
          <div className="form-group">
            <label>Upload Video:</label>
            <input type="file" accept="video/*" onChange={handleFileChange} />
          </div>
          <div className="form-group">
            <label>Number of Clips:</label>
            <input
              type="number"
              value={numClips}
              onChange={(e) => setNumClips(e.target.value)}
              placeholder="Number of Clips"
              disabled={clipWholeVideo}
            />
          </div>
          <div className="form-group">
            <label>Duration of Each Clip (in seconds):</label>
            <input
              type="number"
              value={clipDuration}
              onChange={(e) => setClipDuration(e.target.value)}
              placeholder="Duration of Each Clip"
            />
          </div>
          <div className="form-group checkbox-group">
            <label>
              <input
                type="checkbox"
                checked={clipWholeVideo}
                onChange={() => {
                  setClipWholeVideo(!clipWholeVideo);
                  if (!clipWholeVideo) setRandomClips(false);
                }}
              />
              Clip Whole Video
            </label>
            <label>
              <input
                type="checkbox"
                checked={randomClips}
                onChange={() => setRandomClips(!randomClips)}
                disabled={clipWholeVideo}
              />
              Random Clip Selection
            </label>
          </div>
          <button type="submit" className="btn" disabled={isUploading}>Upload and Split</button>
        </form>

        {uploadError && <p className="error">{uploadError}</p>}

        {isUploading && (
          <div className="progress-output">
            <h3>Processing:</h3>
            <div className="progress-bar-container">
              <div className="progress-bar indeterminate"></div>
            </div>
          </div>
        )}

        {uploadedClips.length > 0 && (
          <div className="clips-output">
            <h3>Clips:</h3>
            <ul className="clip-list">
              {uploadedClips.map((clip, index) => (
                <li key={index}>
                  <a href={`${window.location.origin}${clip}`} download>
                    {clip}
                  </a>
                </li>
              ))}
            </ul>
            {uploadedDownloadAllLink && (
              <div>
                <a href={`${window.location.origin}${uploadedDownloadAllLink}`} download>
                  <button className="btn">Download All Clips</button>
                </a>
              </div>
            )}
          </div>
        )}
      </section>

      {/* YouTube Download and Clip Section */}
      <section className="section">
        <h2>Download and Clip YouTube Video</h2>
        <form onSubmit={handleYoutubeSubmit} className="form">
          <div className="form-group">
            <label>YouTube URL:</label>
            <input
              type="text"
              value={youtubeUrl}
              onChange={(e) => setYoutubeUrl(e.target.value)}
              placeholder="Enter YouTube URL"
            />
          </div>
          <div className="form-group">
            <label>Number of Clips:</label>
            <input
              type="number"
              value={youtubeNumClips}
              onChange={(e) => setYoutubeNumClips(e.target.value)}
              placeholder="Number of Clips"
              disabled={youtubeClipWholeVideo}
            />
          </div>
          <div className="form-group">
            <label>Duration of Each Clip (in seconds):</label>
            <input
              type="number"
              value={youtubeClipDuration}
              onChange={(e) => setYoutubeClipDuration(e.target.value)}
              placeholder="Duration of Each Clip"
            />
          </div>
          <div className="form-group checkbox-group">
            <label>
              <input
                type="checkbox"
                checked={youtubeClipWholeVideo}
                onChange={() => {
                  setYoutubeClipWholeVideo(!youtubeClipWholeVideo);
                  if (!youtubeClipWholeVideo) setYoutubeRandomClips(false);
                }}
              />
              Clip Whole Video
            </label>
            <label>
              <input
                type="checkbox"
                checked={youtubeRandomClips}
                onChange={() => setYoutubeRandomClips(!youtubeRandomClips)}
                disabled={youtubeClipWholeVideo}
              />
              Random Clip Selection
            </label>
          </div>
          <button type="submit" className="btn" disabled={isDownloading}>Download and Clip</button>
          <button type="button" className="btn" onClick={stopDownload} disabled={!isDownloading}>Stop Download</button>
        </form>

        {youtubeError && <p className="error">{youtubeError}</p>}

        {isDownloading && (
          <div className="progress-output">
            <h3>Progress:</h3>
            <div className="progress-bar-container">
              <div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
            </div>
            <p>{progressPercentage}%</p>
          </div>
        )}

        {youtubeClips.length > 0 && (
          <div className="clips-output">
            <h3>YouTube Clips:</h3>
            <ul className="clip-list">
              {youtubeClips.map((clip, index) => (
                <li key={index}>
                  <a href={`${window.location.origin}${clip}`} download>
                    {clip}
                  </a>
                </li>
              ))}
            </ul>
            {youtubeDownloadAllLink && (
              <div>
                <a href={`${window.location.origin}${youtubeDownloadAllLink}`} download>
                  <button className="btn">Download All Clips</button>
                </a>
              </div>
            )}
          </div>
        )}
      </section>

      {/* Mix Clips Section */}
      <section className="section">
        <h2>Mix Multiple Clips</h2>
        <form onSubmit={handleMixSubmit} className="form">
          <div className="form-group">
            <label>Upload Multiple Clips:</label>
            <input type="file" multiple accept="video/*" onChange={handleClipUploadChange} />
          </div>
          <div className="form-group">
            <label>Desired Video Length (in seconds):</label>
            <input
              type="number"
              value={mixDuration}
              onChange={(e) => setMixDuration(e.target.value)}
              placeholder="Desired Video Length"
            />
          </div>
          <button type="submit" className="btn" disabled={isMixing}>Mix Clips</button>
        </form>

        {mixError && <p className="error">{mixError}</p>}

        {isMixing && (
          <div className="progress-output">
            <h3>Mixing Clips:</h3>
            <div className="progress-bar-container">
              <div className="progress-bar indeterminate"></div>
            </div>
          </div>
        )}

        {mixedVideo && (
          <div className="clips-output">
            <h3>Mixed Video:</h3>
            <a href={`${window.location.origin}${mixedVideo}`} download>
              Download Mixed Video
            </a>
          </div>
        )}
      </section>
    </div>
  );
}

export default App;
